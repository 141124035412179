import { Splide } from "@splidejs/splide";

// JavaScript code for the custom web component
class ProductMediaSlider extends HTMLElement {
    constructor() {
      super();
    }

    mountSlider() {
      console.log('mount')
      const slider = new Splide(this, {
        height     : '100%',
        type       : 'loop',
        pagination : true,
        arrows     : true,
        perPage    : 1,
        arrowPath  : 'm2.2742.9395 20 19.9999-20 20.0001-2.1149-2.115 17.8851-17.8851-17.8851-17.885 2.1149-2.1149z',
        heightRatio: 1.285,
        classes    : {
            arrows: 'splide__arrows product-media-arrows',
            arrow : 'splide__arrow product-media-arrow opacity-1',
            prev  : 'splide__arrow--prev',
            next  : 'splide__arrow--next',
            pagination : 'splide__pagination media-slider__pagination lg:hidden relative', // container
            page       : 'media-slider__page', // each button
        }
      });

      slider.mount();
    }
  
}
  
  customElements.define('product-media', ProductMediaSlider);
  